$red: rgba(255, 0, 0, 1);
$lightgray: rgba(166, 168, 179, 0.12);
$lightgray2: rgba(166, 168, 179, 0.5);
$verylightgray: rgba(0, 0, 0, 0.2);
$darkgray: rgba(0, 0, 0, 0.8);
$blue: rgba(0, 0, 255, 1);
$white: rgba(255, 255, 255, 1);
$transparent: rgba(0, 0, 0, 0);
$green: rgba(0, 150, 0, 1);
$lightgreen: rgba(112, 255, 112, 1);
$lightgreen2: rgba(112, 255, 112, 0.5);
$middlegreen: #40ff40;

@import 'drawer-reservation.scss';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Montserrat, "sans-serif";
  overscroll-behavior-y: contain;
}

.app-access-denied {
  padding: 30px;

  h1 {
    text-align: center;
    margin-bottom: 30px;
  }
}

.app-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .login-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 400px;
    padding: 20px;
    background-color: white;
    border-radius: 11px;
    box-shadow: 0px 0px 24px -6px $darkgray;

    h1 {
      margin-bottom: 30px;
    }

    .login-form {
      display: flex;
      flex-direction: column;
      width: 100%;

      svg {
        width: 135px;
        margin: auto;
        margin-bottom: 20px;
      }

      .login-input {
        margin-bottom: 10px;
        padding: 10px;
        border: 1px solid $lightgray;
        border-radius: 7px;
        outline: none;
        font-size: 16px;
        transition: all 0.3s ease-out;

        &:focus {
          border: 1px solid $green;
        }
      }

      .login-button {
        margin-top: 10px;
        padding: 10px;
        border: none;
        border-radius: 7px;
        background-color: $green;
        color: $white;
        font-size: 16px;
        cursor: pointer;
        transition: all 0.3s ease-out;

        &:hover {
          background-color: $lightgreen;
        }
      }
    }
  }

}

.app-loading_wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;

  >div {
    margin: auto;

    h2 {
      text-align: center;
      margin-top: -30px;
    }
  }
}

.App {
  display: flex;
  flex-direction: column;

  .overlays {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $lightgray;
    z-index: 100;
    display: none;
    flex-direction: column;
    justify-content: center;

    .single_overlay {

      &.login_wrapper {
        background-color: white;
        display: none;
        margin: auto;
        max-width: 370px;
        width: 100%;
        padding: 20px;
        text-align: center;
        border-radius: 11px;

      }
    }



    &.shown {

      &-login {
        display: flex;

        .login_wrapper {
          display: block;
        }
      }
    }
  }

  .site-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1000;
    backdrop-filter: blur(0px);
    background-color: rgba(255, 255, 255, 0);
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s ease-out, backdrop-filter 0.3s ease-out, background-color 0.3s ease-out;
    // transition: all 0.3s ease-out;

    &.shown {
      backdrop-filter: blur(3px);
      background-color: rgba(255, 255, 255, 0.3);
      visibility: visible;
      opacity: 1;
    }
  }

  .side-menu_wrapper {
    position: absolute;
    background-color: white;
    height: 100vh;
    z-index: 1000;
    width: 400px;
    left: -400px;
    transition: all 0.3s ease-out;
    overflow: hidden;

    @media all and (max-width: 768px) {
      width: 90vw;
      left: -90vw;
    }

    &.shown {
      left: 0;
      box-shadow: 0px 0px 45px 0px black;
    }

    .side-menu_top {
      padding: 10px;

      svg {
        font-size: 40px;
        cursor: pointer;
      }
    }

    .user-data_wrapper {
      padding: 10px;

      .user-data_inner {
        background-color: $white;
        border-radius: 7px;
        box-shadow: 0px 0px 24px -6px $darkgray;
        display: flex;
        flex-direction: row;
        overflow: hidden;


        .user-data-image_wrapper {
          height: 85px;

          img {
            height: 85px;
            width: 85px;
            object-fit: cover;
          }
        }

        .user-data-content_wrapper {
          display: flex;
          flex-direction: column;
          padding: 10px;

          .user-data-name {
            font-weight: bold;
          }

          button {
            padding: 4px;
            min-width: auto;
            background-color: $red;
            margin: 0;
            margin-top: auto;
            cursor: pointer;
          }
        }
      }
    }

    .side-menu_content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .side-menu-view-selector {
        display: flex;
        flex-direction: column;

        h3 {
          padding: 10px;
        }

        .view-selector {
          padding: 10px 20px;
          border-top: 1px solid $lightgray;
          border-bottom: 1px solid $lightgray;
          margin-top: -1px;
          cursor: pointer;

          &.active {
            color: $green;
          }
        }
      }
    }

    .menu-items {
      display: flex;
      flex-direction: column;
      margin-top: 15px;

      h3 {
        padding: 10px;
      }


      .menu-item {
        padding: 10px 20px;
        border-top: 1px solid $lightgray;
        border-bottom: 1px solid $lightgray;
        margin-top: -1px;
        cursor: pointer;

        &.active {
          color: $green;
        }
      }
    }
  }

  .header-wrapper {
    // height: 100px;
    // width: 100%;
    font-size: 22px;
    display: flex;
    flex-direction: row;
    background-color: $white;
    padding: 8px;
    height: 60px;

    .menu-icon_wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0px 10px;
      width: 280px;

      @media all and (max-width: 1024px) {
        width: unset;
      }

      .fa-bars {
        font-size: 35px;
        cursor: pointer;
      }
    }

    .current-dates-shown {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 20px;

      .mc_month_year_wrapper {

        .mc_month {
          font-size: 16px;
          color: $darkgray;
        }

        .mc_year {
          font-size: 16px;
          color: $darkgray;
          text-transform: capitalize;
          margin-left: 5px;
        }
      }
    }

    .menu-arrows_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 70px;
      padding-top: 3px;

      .mc_arrow_prev {
        font-size: 40px;
        cursor: pointer;
      }

      .mc_arrow_next {
        font-size: 40px;
        margin-left: 20px;
        cursor: pointer;
      }
    }

    .mc_top_content {
      display: flex;
      flex-direction: row;
      margin-left: auto;

      select {
        margin: 0;
        margin-right: 40px;
        border: 0px;
        font-size: 16px;
        font-weight: bold;
        padding-right: 0;

        @media all and (max-width: 1024px) {
          display: none;
        }
      }
    }

    .mobile-calendar-button {
      display: none;
      flex-direction: column;
      justify-content: center;
      padding: 0px 10px;

      @media all and (max-width: 1024px) {
        display: flex;
      }

      .fa-calendar-alt {
        font-size: 35px;
      }
    }
  }

  .content-wrapper {
    // height: calc(100vh - 100px);
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: $white;

    .side-wrapper {
      width: 280px;
      height: 100%;
      display: block;

      .embedded_calendar {
        width: 240px;
        margin: auto;

        @media all and (max-width: 1024px) {
          width: 100%;
        }
      }

      @media all and (max-width: 1024px) {
        position: absolute;
        z-index: 100;
        height: auto;
        width: 100%;
        display: none;
        height: calc(100% - 60px);
        overflow: hidden;
        backdrop-filter: blur(3px);
        background-color: rgba(255, 255, 255, 0.3);

        .embedded_calendar {
          box-shadow: 0px 5px 55px 0px black;
          background-color: white;
        }

        &.shown {
          display: block;
        }
      }
    }

    .main-wrapper {
      width: calc(100vw - 280px);
      height: 100%;

      @media all and (max-width: 1024px) {
        width: 100vw;
        overflow-x: scroll;

        .embedded_calendar {
          width: 300vw;
        }
      }
    }
  }
}

.embedded_calendar {
  position: relative;
  max-height: calc(100vh - 60px - 54px);

  &[type="main"] {


    .ec_days_wrapper {

      .ec_days {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;

        &.ec_days_names {

          span {
            position: relative;
            flex: 0 0 calc((100% / 7) - 2px);
            margin: 1px;
            height: 25px;
            line-height: 25px;

            b {
              width: 100%;
              text-align: center;
              display: block;
              text-transform: uppercase;
            }
          }
        }

        &.ec_days_current_month {
          max-height: calc(100vh - 60px - 25px - 54px);
          overflow-y: auto;
          overflow-x: hidden;
        }

        .single-day {
          position: relative;
          flex: 0 0 calc((100% / 7) - 0px);
          max-width: calc((100% / 7) - 0px);
          margin: -1px;
          border: 1px solid rgba(166, 168, 179, 0.12);

          &:not(:empty) {
            text-align: center;
            background-color: $white;
            color: $darkgray;
          }

          &.not-this-month {
            // background-color: $verylightgray;
          }

          &.ec_day_holiday {
            .day-params {
              background-color: #ffe3e3;
            }
          }

          .day-params {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: 40px;

            .day-params-day {
              padding-left: 5px;
              padding-right: 5px;
              line-height: 40px;
              font-weight: bold;

              .day-day {
                // display: flex;
                // flex-direction: column;
                // justify-content: center;
                font-size: 22px;
                line-height: 40px;
              }
            }

            .day-params-data {
              .kanoecount {
                line-height: 40px;
              }

              .katcount {
                line-height: 40px;
                display: none;
              }
            }

            .day-event-add_wrapper {
              .day-event-add {
                margin-top: 6px;
                margin-right: 5px;
                width: 28px;
                height: 28px;
                color: $green;
                cursor: pointer;
              }
            }
          }

          .day-data-reservations {

            .calendar-reservation {
              // white-space: nowrap;
              height: 25px;
              padding: 3px;
              display: flex;
              flex-direction: row;
              margin-bottom: 2px;
              position: relative;
              cursor: pointer;
              padding-left: 0px;
              border-radius: 4px;
              border-left: 7px solid;
              overflow: hidden;
              opacity: 0;

              &.canceled {
                p {
                  text-decoration: line-through;
                }
              }

              >p {
                position: absolute;
                white-space: nowrap;
                line-height: 20px;
                margin-left: 7px;
              }

              &:not(.reservation-empty) {
                transition: all 0.3s ease-out;
                animation: fadeIn 0.3s ease-out forwards;

                &:hover,
                &.hover {
                  filter: brightness(0%);
                  z-index: 20;
                }
              }

              &.reservation-placeholder {
                z-index: 0;
                pointer-events: none;
                border-left: 0px;

                &.reservation_continuing {
                  background-color: rgba(0, 0, 0, 0);
                  z-index: 10;
                }
              }

              &.length- {
                &2 {
                  width: calc(2 * (100% + 2px) - 0px);
                  z-index: 3;
                }

                &3 {
                  width: calc(3 * (100% + 2px) - 0px);
                  z-index: 4;
                }

                &4 {
                  width: calc(4 * (100% + 2px) - 0px);
                  z-index: 5;
                }

                &5 {
                  width: calc(5 * (100% + 2px) - 0px);
                  z-index: 6;
                }

                &6 {
                  width: calc(6 * (100% + 2px) - 0px);
                  z-index: 7;
                }

                &7 {
                  width: calc(7 * (100% + 2px) - 0px);
                  z-index: 8;
                }

              }

              &.reservation_continuing {
                &:after {
                  content: "";
                  margin-left: auto;
                  border-top: 13px solid $white;
                  border-bottom: 13px solid $white;
                  border-left: 13px solid transparent;
                  margin-top: -3px;
                  margin-right: -3px;
                }
              }

              &.reservation_start_week_before {
                border: 0px;
                padding-left: 8px;

                &:before {
                  content: "";
                  border-top: 15px solid transparent;
                  border-bottom: 15px solid transparent;
                  border-left: 15px solid $white;
                  border-left-color: inherit;
                  margin-left: -8px;
                  margin-top: -5px;
                }
              }

              .reservation-hover {
                z-index: 50;
                position: absolute;
                top: 20px;
                background-color: gray;
                padding: 20px;
                display: none;

                &.shown {
                  display: block;
                }
              }

            }
          }
        }

        &.ec_days_names {
          height: 25px;

          span {
            background-color: $transparent;
            color: $darkgray;
            padding-top: 0;
            height: 25px;
          }
        }

      }
    }

    &[weeks-to-show="1"] {
      .single-day {
        min-height: calc((100vh - 60px - 25px - 54px) / 1 + 1px);
      }
    }

    &[weeks-to-show="2"] {
      .single-day {
        min-height: calc((100vh - 60px - 25px - 54px) / 2 + 1px);
      }
    }

    &[weeks-to-show="3"] {
      .single-day {
        min-height: calc((100vh - 60px - 25px - 54px) / 3 + 1px);
      }
    }

    &[weeks-to-show="4"] {
      .single-day {
        min-height: calc((100vh - 60px - 25px - 54px) / 4 + 1px);
      }
    }

    &[weeks-to-show="5"] {
      .single-day {
        min-height: calc((100vh - 60px - 25px - 54px) / 5 + 1px);
      }
    }

    &[weeks-to-show="6"] {
      .single-day {
        min-height: calc((100vh - 60px - 25px - 54px) / 6 + 1px);
      }
    }

    &[days-to-show="3"] {
      @media all and (max-width: 768px) {
        width: 145vw !important;
      }

      .ec_days {
        justify-content: space-around;

        .single-day {
          width: calc(100% / 3 - 0px);
          max-width: unset !important;
          flex: unset !important;
        }
      }
    }

    &[days-to-show="1"] {
      width: 100% !important;

      .ec_days {
        justify-content: space-around;

        .single-day {
          width: calc(100vw - 0px);
          max-width: unset !important;
          flex: unset !important;

          // .calendar-event {
          //   width: calc(1 * (100% + 2px) - 2px) !important;
          // }
        }
      }
    }
  }

  &[type="nav"] {

    .ec_top {
      height: 60px;
      font-size: 22px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: $white;
      padding: 8px;


      .ec_month_year_wrapper {
        margin-left: 10px;

        .ec_month {
          font-size: 16px;
          color: $darkgray;
        }

        .ec_year {
          font-size: 16px;
          color: $darkgray;
          text-transform: capitalize;
          padding-left: 5px;
        }
      }

      .ec_arrows_wrapper {

        .ec_arrow_prev {
          font-size: 35px;
          margin-right: 20px;
          cursor: pointer;
        }

        .ec_arrow_next {
          font-size: 35px;
          margin-right: 15px;
          cursor: pointer;
        }
      }
    }

    .ec_days_wrapper {

      .ec_days {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;

        >span {
          position: relative;
          flex: 0 0 calc((100% / 7) - 2px);
          margin: 1px;
          padding-top: calc((100% / 7) - 2px);

          &:not(:empty) {
            text-align: center;
            // background-color: $lightgray;
            color: $darkgray;
            cursor: pointer;
            transition: all 0.3s ease-out;

            // padding-top: calc((100% / 7) - 2px);
            //
            &:hover {
              background-color: $lightgray;
            }
          }

          &.not-this-month {
            // background-color: $verylightgray;
          }

          &.ec_day_today {

            // background-color: $blue;
            // color: $green;
            b {
              font-weight: bold;
              text-decoration: underline;
            }
          }

          &.ec_day_holiday {
            b {
              color: $red;
            }
          }

          &.ec_day_selected {
            // background-color: $blue;
            // color: $blue;
            // b {
            //   font-weight: bold;
            //   text-decoration: underline;
            // }
          }

          b {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-weight: 300;
            font-size: 16px;
          }

        }

        &.ec_days_names {
          height: 25px;

          span {
            background-color: $transparent;
            color: $darkgray;
            padding-top: 0;
            height: 25px;
          }
        }

      }
    }
  }
}



.event-popup_wrapper {
  position: absolute;
  backdrop-filter: blur(3px);
  background-color: rgba(255, 255, 255, 0.3);
  top: 0;
  left: 0;
  right: 0;
  min-height: 100vh;
  z-index: 50;
  display: none;
  flex-direction: column;
  justify-content: center;

  &.show {
    &-event-details {
      display: flex;

      .event-details_wrapper {
        display: flex;
      }
    }

    &-event-edit {
      display: flex;

      .event-edit_wrapper {
        display: flex;
      }
    }
  }

  .event-popup-close {
    height: 50px;
    width: 50px;
    background-color: red;
    position: absolute;
    right: 0;
    top: 0;
  }

  .event-popup_content {
    background-color: white;
    margin: auto;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 0px 17px 11px #888888;
    display: none;

    &.event-details_wrapper {
      flex-direction: column;

      .event-details-top {
        display: flex;
        flex-direction: row;
      }

      .event-details {
        display: flex;
        flex-direction: column;
      }
    }

    .event-popup_form {
      display: flex;
      flex-direction: column;
    }
  }
}

/* .ReactModal__Overlay {
  transform: translate(-50%, 0%);
  top: auto;
  bottom: -150%;
  transition: bottom 0.5s ease-out;

  &.ReactModal__Overlay--after-open {
    bottom: 0;
  }

  &.ReactModal__Overlay--before-close {
    bottom: -150%;
  }
} */


.ReactModal__Content {
  padding: 0px !important;
  max-height: calc(100vh - 50px);
  border: 0px !important;
  overflow: hidden;
  transform: translate(-50%, 0%);
  top: auto;
  bottom: -150%;
  transition: bottom 0.5s ease-out;

  &.ReactModal__Content--after-open {
    bottom: 0;
  }

  &.ReactModal__Content--before-close {
    bottom: -150%;
  }

  @media all and (max-width: 768px) {
    width: 100vw;
    max-height: 100vh;
    min-height: 100vh;

    // .modal-reservation-wrapper {
    //   overflow-y: scroll;
    // }
  }

  @media all and (min-width: 1024px) {
    box-shadow: 0px 0px 24px -6px $darkgray;
  }


}

input,
select {
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;

  &[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
}

textarea {
  margin: 8px 0;
  padding: 12px 20px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* button {
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  background: $middlegreen;
  padding: 20px;
  border-radius: 5px;
  display: block;
  border: none;
  transition: all 0.4s ease 0s;
  min-width: 200px;
  margin: auto;
  font-weight: bold;

  &:hover {
    background: $green;
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
    -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
    box-shadow: 5px 40px -10px rgba(0, 0, 0, 0.57);
    transition: all 0.4s ease 0s;
  }

  &:disabled {
    background: $lightgray;
    cursor: not-allowed;
  }
} */

.add-fee-reservation-wrapper,
.add-payment-reservation-wrapper,
.equipment_wrapper {
  padding: 15px 0;
  position: relative;

  .loading-overlay {

    svg {
      top: 50%;
    }
  }

  form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    position: relative;

    label {
      display: flex;
      flex-direction: column;
      width: calc(50% - 10px);
      min-width: 150px;

      @media all and (max-width: 768px) {
        width: calc(100% - 10px);
      }

      input,
      textarea,
      select {
        width: calc(100% - 10px) !important;

      }

      textarea {
        height: 100px;
        min-height: 50px;
        max-height: 300px;
      }

    }

    .payments-giftcard {
      display: flex;
      flex-direction: row;
      width: calc(100% - 10px);
      min-width: 150px;

      div {
        width: calc(100% - 10px);
        position: relative;

        input {
          border-right: 0px;
          border-radius: 4px 0 0 4px;
          padding-right: 0;
          width: calc(100% - 50px) !important;
        }

        .gift-card-camera-icon {
          position: absolute;
          right: 0px;
          bottom: 8px;
          cursor: pointer;
          width: 50px;
          height: 42px;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          border-radius: 0 4px 4px 0;
          border: 1px solid #ccc;
          border-left: 0px;

          svg {
            width: 25px;
            height: 25px;
            margin: auto;
          }
        }
      }

      button {
        margin-left: 10px;
        padding: 13px;
        min-width: unset;
        margin-top: 27px;
      }
    }

    .payments-giftcard-details {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 0px;
      margin-bottom: 5px;
      padding: 20px 0;
      width: calc(100% - 15px);

      div {}
    }

    .buttons_wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-top: 20px;

      button {
        width: calc(50% - 10px);
        min-width: 30px;
        margin: 0 10px;
        max-width: 50%;
      }
    }
  }
}

.offline-mode {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  svg,
  h2 {
    margin: 0 auto;
  }

  h2 {
    margin-top: 20px;
  }
}

.loading-overlay {
  position: absolute;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: none;
  flex-direction: column;
  backdrop-filter: blur(3px);
  background-color: rgba(255, 255, 255, 0.3);

  svg {
    @media all and (max-width: 1024px) {
      position: fixed;
      left: 50vw;
      top: 50vh;
      transform: translate(-50%, -50%);
    }
  }

  h3 {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 63%;
    left: 50%;
  }

  &.show {
    display: flex;
  }

}

#react-confirm-alert-firm-svg {
  display: none;
}

#react-confirm-alert {

  .react-confirm-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2500;
    background: rgba(255, 255, 255, 0.4);
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    -ms-align-items: center;
    align-items: center;

    .react-confirm-alert {
      .react-confirm-alert-body {
        padding: 30px;
        text-align: left;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 20px 75px 0 rgba(0, 0, 0, 0.13);
        color: #666;

        h1 {
          margin-bottom: 15px;
        }

        .react-confirm-alert-button-group {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 25px;

          button {
            flex: 1;
            min-width: unset;
            margin: 0;
            max-width: calc(50% - 20px);

            &:nth-child(2) {
              background-color: $red;

              &:hover {
                background-color: $darkgray;
              }
            }
          }
        }
      }
    }
  }
}

.client-search-input {
  margin: 10px 10px;
  width: calc(100% - 20px);
}

.clients-list_wrapper {
  overflow: scroll;
  max-height: calc(100vh - 133px);
}


.clients-table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;

  thead {
    background-color: $lightgreen2;

    tr {
      th {
        padding: 5px;
        text-align: center;
        white-space: nowrap;

      }
    }
  }

  tbody {
    tr {
      td {
        padding: 5px;
        text-align: center;
        white-space: nowrap;

        @media all and (max-width: 768px) {
          padding: 12px 5px;
        }

        svg {
          margin-left: 15px;
          margin-right: 15px;
          color: $blue;
          transition: all 0.3s ease-out;
          cursor: pointer;

          &:hover {
            color: $darkgray;
          }
        }
      }

      &:nth-child(2n + 1) {
        background-color: $lightgray;
      }

      &:nth-child(2n) {
        background-color: $lightgray2;
      }
    }
  }
}

.drawer_wrapper_fade {
  z-index: 1400;
  position: absolute;


  .drawer_wrapper_main {
    .drawer_overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.5);

    }

    .drawer_wrapper {
      max-width: 900px;
      background-color: $white;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 70vh;
      border-radius: 16px 16px 0 0;
      transform: translateX(-50%);
      left: 50%;

      &.animation-enabled {
        transition: all 0.3s ease-out;
      }

      .drawer_handle_wrapper {
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .drawer_handle {
          width: 150px;
          height: 6px;
          background-color: $darkgray;
          margin: 0 auto;
          border-radius: 20px;
          cursor: grab;
        }
      }



      .drawer_content {
        padding: 10px;
        height: calc(100% - 40px);
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.ptr {
  .ptr__pull-down {
    max-width: 100vw;
  }
}
