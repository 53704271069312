.modal-reservation-wrapper {

  .modal-title_wrapper {
    text-align: center;

    h3 {}

    .icons-wrapper {
      padding-top: 2px;

      @media all and (max-width: 768px) {
        display: flex;
        flex-direction: row;
      }
    }

    svg {
      width: 30px;
      height: 30px;
      margin-top: -5px;
      margin-bottom: -8px;
      cursor: pointer;
      transition: all 0.3s ease-out;

      &:hover {
        color: $red;
      }

      &.delete-reservation-icon {
        margin-right: 15px;
        height: 22px;

        @media all and (min-width: 768px) {
          margin-top: -27px;
          margin-bottom: -3px;
        }

        &:hover {
          color: $red;
        }
      }
    }

    .history-reservation-icon {
      /* width: 25px;
        height: 25px; */
      margin-right: 10px;
      /* 
        margin-top: -2px;
        margin-bottom: -6px; */
    }

    .icons-wrapper {
      display: flex;
      flex-direction: row;
    }

    @media all and (max-width: 768px) {
      height: 75px;

      h3 {
        margin: auto 0;
      }

      .icons_wrapper {
        display: flex;
        flex-direction: row;
        margin: auto 0;
        //display: block;
      }

      svg {
        width: 40px;
        height: 40px;
        margin: auto 0;
      }
    }
  }

  &.add-reservation {
    .modal-content-wrapper {}
  }

  .edit-reservation-wrapper {
    padding: 10px;

    /* input,
    select,
    textarea,
    button {
      width: 100%;
    }

    textarea {
      min-width: 100%;
      max-width: 100%;
      min-height: 100px
    } */
  }


  .modal-content_wrapper {
    min-height: 380px;
    overflow-y: auto;
    max-height: calc(100vh - 50px - 43px);

    @media all and (max-width: 768px) {
      max-height: calc(100vh - 75px);

    }

    .kanoe-count-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      label {
        max-width: calc(50% - 10px);
      }
    }

    .history_title {
      margin: 10px 0;
      text-align: center;
    }

    .history_wrapper {
      padding: 10px;
      padding-top: 0;
      min-width: 30vw;
      display: flex;
      flex-direction: column;


      .reservation-history-part {
        display: flex;
        flex-direction: row;
        min-height: 100px;

        .left-side {
          width: 10%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .circle-number {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background-color: $lightgreen;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.2em;
            font-weight: bold;
            margin: auto;
            z-index: 50;
          }
        }

        .right-side {
          width: 90%;
          padding: 20px 0;
          padding-left: 25px;
          border-left: 1px solid $verylightgray;
          border-radius: 10px 0 0 10px;
          margin-left: -28px;

          .history-part-header {
            p {
              font-weight: bold;

              span {
                font-weight: normal;
              }
            }
          }

          .history-part-values {
            margin-top: 15px;

            .old-value {
              color: $red;
            }

            .history-values-icon {
              margin: 0 5px;
            }

            .new-value {
              color: $green;
            }
          }
        }
      }
    }

    .columns {
      display: flex;
      flex-direction: row;

      @media all and (max-width: 768px) {
        flex-direction: column-reverse;
      }

      .column {
        flex: 1;
        padding: 10px;

        table {
          tr {
            td {
              padding-right: 5px;
            }
          }
        }
      }
    }

    .note-wrapper {
      padding: 10px 0;
      width: 100%;
      padding: 10px;
    }

    .tabs-wrapper {
      padding: 10px;
      padding-bottom: 0;

      .tabs-labels {
        display: flex;
        flex-direction: row;

        .tab-label {
          padding: 15px;
          border-radius: 20px 20px 0px 0px;
          background-color: $lightgray;
          cursor: pointer;

          &.active {
            font-weight: bold;
            background-color: $lightgreen;
          }
        }
      }

      .tabs {

        .tab {
          display: none;

          .nothing-yet {
            text-align: center;
            margin-top: 10px;
            padding: 15px;
          }

          .fees-table {
            width: 100%;
            overflow-x: auto;
          }

          table {
            border-collapse: collapse;
            border-spacing: 0;
            width: 100%;

            thead {
              background-color: $lightgreen2;

              tr {
                th {
                  padding: 5px;
                  text-align: center;
                  white-space: nowrap;

                }
              }
            }

            tbody {
              tr {
                td {
                  padding: 5px;
                  text-align: center;
                  white-space: nowrap;

                  @media all and (max-width: 768px) {
                    padding: 12px 5px;
                  }

                  svg {
                    margin-left: 15px;
                    color: $red;
                    transition: all 0.3s ease-out;
                    cursor: pointer;

                    &:hover {
                      color: $darkgray;
                    }
                  }
                }

                &:nth-child(2n + 1) {
                  background-color: $lightgray;
                }

                &:nth-child(2n) {
                  background-color: $lightgray2;
                }
              }
            }
          }
        }

        &.show- {
          &1 {
            .tab-1 {
              display: block;
              border: 4px solid $lightgreen2;
              min-height: 200px;
            }
          }

          &2 {
            .tab-2 {
              display: block;
              border: 4px solid $lightgreen2;
              min-height: 200px;
            }
          }

          &3 {
            .tab-3 {
              display: block;
              border: 4px solid $lightgreen2;
              min-height: 200px;
            }
          }

          &4 {
            .tab-4 {
              display: block;
              border: 4px solid $lightgreen2;
              min-height: 200px;
            }
          }
        }

      }
    }
  }
}
